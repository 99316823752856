
import { defineComponent, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EventResource from '@/resources/EventResource';
import Form from '@/components/Event/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const event = reactive({});
        const form = useForm(event, EventResource);
        const { isSaving, update  } = form;

        onMounted(() => {
            EventResource.get({id: Number(route.params.id)}).then((response) => {
                Object.assign(event, response);
            });
        });

        function submit() {
            update().then(() => {
                router.go(-1);
            });
        }

        return {
            event,
            form,
            isSaving,
            submit,
        };
    },
});
