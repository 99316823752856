import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelText as _vModelText, normalizeClass as _normalizeClass, withDirectives as _withDirectives, toHandlers as _toHandlers, mergeProps as _mergeProps, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message is-danger"
}
const _hoisted_2 = { class: "message-body" }
const _hoisted_3 = { class: "field is-horizontal" }
const _hoisted_4 = { class: "field-label is-normal" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "field-body" }
const _hoisted_7 = { class: "field" }
const _hoisted_8 = { class: "control" }
const _hoisted_9 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_10 = { class: "field is-horizontal" }
const _hoisted_11 = { class: "field-label is-normal" }
const _hoisted_12 = { class: "label" }
const _hoisted_13 = { class: "field-body" }
const _hoisted_14 = { class: "field" }
const _hoisted_15 = { class: "control" }
const _hoisted_16 = ["value"]
const _hoisted_17 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_18 = { class: "field is-horizontal" }
const _hoisted_19 = { class: "field-label is-normal" }
const _hoisted_20 = { class: "label" }
const _hoisted_21 = { class: "field-body" }
const _hoisted_22 = { class: "field" }
const _hoisted_23 = { class: "control" }
const _hoisted_24 = ["value"]
const _hoisted_25 = {
  key: 0,
  class: "help is-danger"
}
const _hoisted_26 = { class: "field is-horizontal" }
const _hoisted_27 = { class: "field-label is-normal" }
const _hoisted_28 = { class: "label" }
const _hoisted_29 = { class: "field-body" }
const _hoisted_30 = { class: "field" }
const _hoisted_31 = { class: "control" }
const _hoisted_32 = {
  key: 0,
  class: "help is-danger"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DatePicker = _resolveComponent("DatePicker")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.errors.message)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.errors.message), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('event.NAME')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _withDirectives(_createElementVNode("input", {
              type: "text",
              class: _normalizeClass(["input", { 'is-danger': _ctx.errors.name }]),
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.name) = $event))
            }, null, 2), [
              [_vModelText, _ctx.model.name]
            ])
          ]),
          (_ctx.errors.name)
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(_ctx.errors.name), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t('event.START_DATE')), 1)
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_DatePicker, {
              modelValue: _ctx.model.start_date,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.start_date) = $event)),
              "model-config": { type: 'string', mask: 'DD.MM.YYYY' },
              masks: { input: 'DD.MM.YYYY' },
              popover: { visibility: 'click' },
              "first-day-of-week": 2
            }, {
              default: _withCtx(({ inputValue, inputEvents }) => [
                _createElementVNode("input", _mergeProps({
                  class: ["input", { 'is-danger': _ctx.errors.start_date }],
                  value: inputValue
                }, _toHandlers(inputEvents)), null, 16, _hoisted_16)
              ]),
              _: 1
            }, 8, ["modelValue", "model-config", "masks"])
          ]),
          (_ctx.errors.start_date)
            ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_ctx.errors.start_date), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t('event.END_DATE')), 1)
      ]),
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, [
            _createVNode(_component_DatePicker, {
              modelValue: _ctx.model.end_date,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.model.end_date) = $event)),
              "model-config": { type: 'string', mask: 'DD.MM.YYYY' },
              masks: { input: 'DD.MM.YYYY' },
              popover: { visibility: 'click' },
              "first-day-of-week": 2
            }, {
              default: _withCtx(({ inputValue, inputEvents }) => [
                _createElementVNode("input", _mergeProps({
                  class: ["input", { 'is-danger': _ctx.errors.end_date }],
                  value: inputValue
                }, _toHandlers(inputEvents)), null, 16, _hoisted_24)
              ]),
              _: 1
            }, 8, ["modelValue", "model-config", "masks"])
          ]),
          (_ctx.errors.end_date)
            ? (_openBlock(), _createElementBlock("p", _hoisted_25, _toDisplayString(_ctx.errors.end_date), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_26, [
      _createElementVNode("div", _hoisted_27, [
        _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t('event.IS_ARCHIVED')), 1)
      ]),
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, [
            _withDirectives(_createElementVNode("input", {
              type: "checkbox",
              class: _normalizeClass({ 'is-danger': _ctx.errors.is_archived }),
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.model.is_archived) = $event))
            }, null, 2), [
              [_vModelCheckbox, _ctx.model.is_archived]
            ])
          ]),
          (_ctx.errors.is_archived)
            ? (_openBlock(), _createElementBlock("p", _hoisted_32, _toDisplayString(_ctx.errors.is_archived), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ])
  ], 64))
}