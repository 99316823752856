
import { defineComponent, toRefs } from 'vue';
import { DatePicker } from 'v-calendar';

export default defineComponent({
    components: { DatePicker },
    props: ['form'],

    setup(props) {
        const { model, errors } = toRefs(props.form.state);

        return {
            model,
            errors
        };
    },
});
